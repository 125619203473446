<template>
    <div class="col-9 col-xs-12 col-md-9">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="clearFilter"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="standardShippingDocuments"
                        table-id="standardShippingDocumentsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateStandardShippingDocumentDialog()"
                    ></ExportTable>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.standardShippingDocuments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allStandardShippingDocuments"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'Document.title'"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="standardShippingDocumentsTable"
            item-key="Document.id"
        >
            <template v-slot:item.Document.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditStandardShippingDocumentDialog(item.Document.id, item.Document.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateStandardDocument') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteStandardShippingDocument(item.Document.id, item.Document.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteStandardDocument') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:item.Document.doctype_id="{ item }">
                <div class="ml-3 font-weight-bold">{{ item.Document.doctype_id}}</div>
            </template>
            <template v-slot:item.Document.title="{ item }">
                <span class="font-weight-bold">{{ item.Document.title}}</span>
            </template>
            <template v-slot:item.Document.customeraccess="{ item }">
                <div class="ml-5">
                    <v-icon v-if="item.Document.customeraccess == 1" color="green darken-1" class="mdi mdi-lock-open-outline"></v-icon>
                    <v-icon v-else color="red darken-1" class="mdi mdi-lock-outline"></v-icon>
                </div>
            </template>
            <template v-slot:item.Document.categories="{ item, index }">
                <div class="d-flex flex-column">
                    <v-chip class="mr-1 mb-1" v-for="category in item.Document.categories" small :key="makeId(5)">{{ $t('message.docTypes.' + category) }}</v-chip>
                </div>
            </template>
            <template v-slot:item.Document.standard="{ item, index }">
                <div class="d-flex flex-column">
                    <v-chip :class="'mr-1 mb-1' + (ct == 1 ? ' blue--text text--darken-1' : ' pink--text text--darken-1')" v-for="ct in item.Document.categories_types" small :key="makeId(5)">{{ ct == 1 ? $t('message.standard') : $t('message.nonStandard') }}</v-chip>
                </div>
            </template>
            <template v-slot:item.Document.sort="{ item, index }">
                <div class="d-flex flex-column align-center">
                    <template>
                        <div class="sort-id mb-1"
                             v-for="(ct, key) in item.Document.categories" small :key="'sort_' + key"
                             @click="openEditStandardShippingDocumentSortDialog(item.Document.id, item.Document.title, item.Document.categories_sortid, ct)"
                             v-if="ct in item.Document.categories_sortid"
                        >{{ item.Document.categories_sortid[ct] }}</div>
                        <div class="sort-id mb-1 red--text text--darken-1"
                             @click="openEditStandardShippingDocumentSortDialog(item.Document.id, item.Document.title, item.Document.categories_sortid, ct)"
                             v-else
                        >{{ '?' }}</div>
                    </template>
                </div>
            </template>
            <template v-slot:item.Document.linked="{ item }">
                <span :class="item.Document.linked == 1 ? 'green--text text--darken-2 font-weight-bold' : ''">{{ item.Document.linked == 1 ? $t('message.yes') : $t('message.no') }}</span>
            </template>
            <template v-slot:item.Document.autogenerated="{ item }">
                <span>{{ item.Document.autogenerated == 1 ? $t('message.yes') : $t('message.no') }}</span>
            </template>
        </v-data-table>
        <AddStandardShippingDocument
            :standard-shipping-document-id="selectedStandardShippingDocumentId"
            :standard-shipping-document="selectedStandardShippingDocument"
            :dialog.sync="editDialog"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="standardShippingDocumentUpdated"
            @create-done="standardShippingDocumentCreated"
        ></AddStandardShippingDocument>
        <UpdateStandardShippingDocumentSorting
            :standard-shipping-document-id="selectedStandardShippingDocumentToSortId"
            :standard-shipping-document="selectedStandardShippingDocumentToSort"
            :standard-shipping-document-category="selectedStandardShippingDocumentToSortCategory"
            :dialog.sync="editSortDialog"
            :page-key="pageSortKey"
            :update-mode="updateSortMode"
            @dialog-closed="editSortDialogClosed"
            @update-done="standardShippingDocumentSortingUpdated"
        ></UpdateStandardShippingDocumentSorting>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from 'vuex'
import {log, makeId} from "Helpers/helpers";
import UpdateStandardShippingDocumentSorting from "Components/Appic/UpdateStandardShippingDocumentSorting";

const ExportTable = () => import("Components/Appic/ExportTable");
const AddStandardShippingDocument = () => import('Components/Appic/AddStandardShippingDocument');

export default {
    name: "StandardShippingDocumentsListing",
    components: {UpdateStandardShippingDocumentSorting, AddStandardShippingDocument, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: false,
            editSortDialog: false,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                standardShippingDocuments: false
            },
            searchField: null,
            searchTerm: null,
            selectedStandardShippingDocument: null,
            selectedStandardShippingDocumentId: null,
            selectedStandardShippingDocumentToSort: null,
            selectedStandardShippingDocumentToSortCategory: null,
            selectedStandardShippingDocumentToSortId: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalStandardShippingDocuments: 0,
            updateMode: false,
            updateSortMode: false
        }
    },
    computed: {
        ...mapGetters('document',{
            allStandardShippingDocuments: 'allStandardShippingDocuments',
            documentCategories: 'documentCategories'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Document.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.title'),
                    value: 'Document.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.typeId'),
                    value: 'Document.doctype_id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.categories'),
                    value: 'Document.categories',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.type'),
                    value: 'Document.standard',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 6,
                    text: this.$t('message.sortId'),
                    value: 'Document.sort',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 7,
                    text: this.$t('message.customerAccess'),
                    value: 'Document.customeraccess',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.fileName'),
                    value: 'Document.fileName',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 9,
                    text: this.$t('message.linked'),
                    value: 'Document.linked',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 10,
                    text: this.$t('message.autogenerated'),
                    value: 'Document.autogenerated',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        },
        pageSortKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('document',{
            deleteStandardShippingDocumentById: 'deleteStandardShippingDocumentById',
            getAllStandardShippingDocuments: 'getAllStandardShippingDocuments',
            resetStandardShipmentDocumentState: 'resetStandardShipmentDocumentState'
        }),
        clearFilter() {
            this.searchTerm = null
            this.resetStandardShipmentDocumentState()
            this.getAllStandardShippingDocuments()
        },
        async deleteStandardShippingDocument (val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteStandardShippingDocument') + ' ' +  title, this.$t('message.confirmations.continueStandardShippingDocumentCancelAction'), {color: 'orange'})){
                this.deleteStandardShippingDocumentById(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.standardShippingDocumentDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetStandardShipmentDocumentState()
                            this.getAllStandardShippingDocuments()
                        } else {
                            this.$toast.error(this.$t('message.errors.standardShippingDocumentNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.standardShippingDocumentNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('edit-dialog-closed')
            }
        },
        editSortDialogClosed () {
            this.editSortDialog = false;
            if(this.updateSortMode == false){
                this.$emit('edit-sort-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        makeId,
        openCreateStandardShippingDocumentDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditStandardShippingDocumentDialog (documentId, document) {
            this.selectedStandardShippingDocumentId = documentId
            this.selectedStandardShippingDocument = document
            this.updateMode = true
            this.editDialog = true
        },
        openEditStandardShippingDocumentSortDialog (documentId, document, sortData, category) {
            this.selectedStandardShippingDocumentToSortId = documentId
            this.selectedStandardShippingDocumentToSort = document
            this.selectedStandardShippingDocumentToSortCategory = category
            this.updateSortMode = true
            if(Object.keys(sortData).length === 0){
                this.updateSortMode = false
            }
            this.editSortDialog = true
        },
        standardShippingDocumentCreated () {
            this.editDialog = false;
            this.resetStandardShipmentDocumentState()
            this.getAllStandardShippingDocuments()
        },
        standardShippingDocumentUpdated () {
            this.editDialog = false;
            this.resetStandardShipmentDocumentState()
            this.getAllStandardShippingDocuments()
        },
        standardShippingDocumentSortingUpdated() {
            this.editSortDialog = false;
            this.resetStandardShipmentDocumentState()
            this.getAllStandardShippingDocuments()
        }
    },
    created() {
        this.resetStandardShipmentDocumentState()
        if(this.allStandardShippingDocuments.length == 0) this.getAllStandardShippingDocuments()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
.sort-id {
    cursor: pointer;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: lightgray;
    display: inline-flex !important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column !important;
    justify-content: center;
    position: relative;
}
</style>