var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-9 col-xs-12 col-md-9"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.clearFilter}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTable',{staticClass:"mt-0 mr-0",staticStyle:{"padding-top":"2px !important"},attrs:{"has-add-function":true,"cols-to-delete":_vm.colsToDelete,"file-name":"standardShippingDocuments","table-id":"standardShippingDocumentsTable"},on:{"add-action":function($event){return _vm.openCreateStandardShippingDocumentDialog()}}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.standardShippingDocuments,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
            itemsPerPageOptions: [20,50,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.allStandardShippingDocuments,"items-per-page":-1,"search":_vm.searchTerm,"options":_vm.tableOptions,"sort-by":'Document.title',"calculate-widths":"","dense":"","fixed-header":"","id":"standardShippingDocumentsTable","item-key":"Document.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.Document.id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditStandardShippingDocumentDialog(item.Document.id, item.Document.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateStandardDocument'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteStandardShippingDocument(item.Document.id, item.Document.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteStandardDocument')))],1)],1)],1)],1)]}},{key:"item.Document.doctype_id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"ml-3 font-weight-bold"},[_vm._v(_vm._s(item.Document.doctype_id))])]}},{key:"item.Document.title",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Document.title))])]}},{key:"item.Document.customeraccess",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"ml-5"},[(item.Document.customeraccess == 1)?_c('v-icon',{staticClass:"mdi mdi-lock-open-outline",attrs:{"color":"green darken-1"}}):_c('v-icon',{staticClass:"mdi mdi-lock-outline",attrs:{"color":"red darken-1"}})],1)]}},{key:"item.Document.categories",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.Document.categories),function(category){return _c('v-chip',{key:_vm.makeId(5),staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('message.docTypes.' + category)))])}),1)]}},{key:"item.Document.standard",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.Document.categories_types),function(ct){return _c('v-chip',{key:_vm.makeId(5),class:'mr-1 mb-1' + (ct == 1 ? ' blue--text text--darken-1' : ' pink--text text--darken-1'),attrs:{"small":""}},[_vm._v(_vm._s(ct == 1 ? _vm.$t('message.standard') : _vm.$t('message.nonStandard')))])}),1)]}},{key:"item.Document.sort",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_vm._l((item.Document.categories),function(ct,key){return (ct in item.Document.categories_sortid)?_c('div',{key:'sort_' + key,staticClass:"sort-id mb-1",attrs:{"small":""},on:{"click":function($event){return _vm.openEditStandardShippingDocumentSortDialog(item.Document.id, item.Document.title, item.Document.categories_sortid, ct)}}},[_vm._v(_vm._s(item.Document.categories_sortid[ct]))]):_c('div',{staticClass:"sort-id mb-1 red--text text--darken-1",on:{"click":function($event){return _vm.openEditStandardShippingDocumentSortDialog(item.Document.id, item.Document.title, item.Document.categories_sortid, ct)}}},[_vm._v(_vm._s('?'))])})],2)]}},{key:"item.Document.linked",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:item.Document.linked == 1 ? 'green--text text--darken-2 font-weight-bold' : ''},[_vm._v(_vm._s(item.Document.linked == 1 ? _vm.$t('message.yes') : _vm.$t('message.no')))])]}},{key:"item.Document.autogenerated",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Document.autogenerated == 1 ? _vm.$t('message.yes') : _vm.$t('message.no')))])]}}])}),_c('AddStandardShippingDocument',{attrs:{"standard-shipping-document-id":_vm.selectedStandardShippingDocumentId,"standard-shipping-document":_vm.selectedStandardShippingDocument,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.standardShippingDocumentUpdated,"create-done":_vm.standardShippingDocumentCreated}}),_c('UpdateStandardShippingDocumentSorting',{attrs:{"standard-shipping-document-id":_vm.selectedStandardShippingDocumentToSortId,"standard-shipping-document":_vm.selectedStandardShippingDocumentToSort,"standard-shipping-document-category":_vm.selectedStandardShippingDocumentToSortCategory,"dialog":_vm.editSortDialog,"page-key":_vm.pageSortKey,"update-mode":_vm.updateSortMode},on:{"update:dialog":function($event){_vm.editSortDialog=$event},"dialog-closed":_vm.editSortDialogClosed,"update-done":_vm.standardShippingDocumentSortingUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }